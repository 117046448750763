$(document).ready(() => {

    new Swiper('.main-block-slider',{
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: '.main-block .slider-arrow-next',
            prevEl: '.main-block .slider-arrow-prev'
        }

    });
    new Swiper('.persons-slider.swiper',{
        slidesPerView: 3,
        // slidesPerColumn: 2,
        autoHeight: true,
        spaceBetween: 50,

        effect: "coverflow",
        centeredSlides: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: false
        },
        breakpoints: {
            1024: {
                slidesPerView: 3,
                // spaceBetween: 50,
                centeredSlides: true,
            },
            320: {
                slidesPerView: 1,
                // spaceBetween: 20,
                centeredSlides: true,
            },
            
            
        },
        navigation: {
            nextEl: '.persons-section .slider-arrow-next',
            prevEl: '.persons-section  .slider-arrow-prev'
        }
    });
    new Swiper('.partners-list.swiper',{
        slidesPerView: 1,
        // slidesPerColumn: 2,
        // autoHeight: true,
        spaceBetween: 50,
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: '.partners-section .slider-arrow-next',
            prevEl: '.partners-section .slider-arrow-prev'
        },
        breakpoints: {
            1024: {
                slidesPerView: 4,
                // spaceBetween: 50,
               
            },
            768: {
                slidesPerView: 3,
                // spaceBetween: 20,
                
            },
            540: {
                slidesPerView: 2,
                // spaceBetween: 20,
                
            },
            320: {
                slidesPerView: 1,
                // spaceBetween: 20,
                
            },
        }
        
    });
    
    new Swiper('.page-slider.swiper',{
        effect: "coverflow",
        
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 50,
        loop: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: false
        },
        navigation: {
            nextEl: '.page-slider-section .slider-arrow-next',
            prevEl: '.page-slider-section .slider-arrow-prev'
        },
        breakpoints: {
            1024: {
                slidesPerView: 3,
                // spaceBetween: 50,
               
            },
            768: {
                slidesPerView: 2,
                // spaceBetween: 20,
                
            },
            540: {
                slidesPerView: 1,
                // spaceBetween: 20,
                
            }
        }
    });


    $('.tab-content-item').hide();

    $(document).on('click', '.tab-item' , e => {
        
        const tab = $(e.currentTarget);
        $('.tab-item').removeClass('active');
        tab.addClass('active');
        console.log(tab.index());
        const currentIndex = tab.index();
        const tabContent = $('.tab-content-item');
        
        tabContent.hide().removeClass('active').eq(currentIndex).show().addClass('active');
    });
});