$(document).ready(() => {

    $(document).on('click', '.close-menu', closeMenu);
    $(document).on('click', '.menu-button', openMenu);

    const mobileMenu = $('.main-nav .menu').clone().append('<div class="close-menu"></div>');
    const burger = $('.main-nav .menu').after(`
        <div class="menu-button">
            
        </div>
    `);


    $('body').append(`
    <div class="mobile-menu-wrapper">
        ${mobileMenu[0].outerHTML}
    </div>
    `);
    

    
});

const hideMenu = e => {

    // let moreMenu = $('nav .container .menu-more .submenu > ul');

    let plusWidth = 250;
    let tmpWidth=0; 
    let isMore = false;
   
    $('.main-nav .menu li').each((e,el)=>{
        
        $(el).show();
        if (isMore){
            $(el).hide();
        }

        tmpWidth += $(el).outerWidth() + 40;
        
        if (tmpWidth+plusWidth >= $('.main-nav .container').width()) isMore = true;

    });
    if (isMore) {
        $('.menu-button').show();
    } else {
        $('.menu-button').hide();
    }
}

$(window).on('resize', e => {
    // console.log($(window).width());
    hideMenu();
});

const openMenu = e => {
    
    $('.mobile-menu-wrapper').addClass('show');
}

const closeMenu = e => {
    
    $('.mobile-menu-wrapper').removeClass('show');
}